<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col md="4" cols="12">
                <base-card class="h-full">
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-avatar size="90" class="mt-2">
                                <v-img :src="`/assets/gaa/teams/icons/${getTeam.code}.WEBP`" />
                            </v-avatar>
                            <div class="card-title ma-0 text-h5">{{ getTeam.name }}</div>
                            <p class="text--disabled mb-1">{{ getTeam.provice }}</p>
                            <div class="d-flex align-center justify-center cursor-pointer"
                                @click="openMapWithAddress(getTeam.postcode)">
                                <v-icon class="text-primary body-1 mr-1">mdi-map-marker</v-icon>
                                <span class="text-primary text-12">{{ getTeam.city }}, {{ getTeam.name }}</span>
                            </div>
                        </v-col>
                        <v-col cols="12" class="mb-0">
                            <v-card-subtitle class="py-0">
                                <h5 class="">
                                    Basic Info
                                </h5>
                            </v-card-subtitle>
                            <v-card-text class="pb-0">
                                <v-row>
                                    <v-col cols="5" class="py-1">
                                        <b>Nickname</b>
                                    </v-col>
                                    <v-col cols="2" />
                                    <v-col cols="5" class="py-1">
                                        {{ getTeam.nickname }}
                                    </v-col>

                                    <v-col cols="5" class="py-1">
                                        <b>Colours</b>
                                    </v-col>
                                    <v-col cols="2" />
                                    <v-col cols="5" class="py-1">
                                        {{ getTeam.coloursDescription }}
                                    </v-col>

                                    <v-col cols="5" class="py-1">
                                        <b>Stadium</b>
                                    </v-col>
                                    <v-col cols="2" />
                                    <v-col cols="5" class="py-1">
                                        {{ getTeam.stadiumName }}
                                    </v-col>
                                    <v-col cols="1" />
                                    <v-col cols="5" class="py-0 text-caption">
                                        <b>Capacity</b>
                                    </v-col>
                                    <v-col cols="1" />
                                    <v-col cols="5" class="py-0 text-caption">
                                        {{ getTeam.stadiumCapacity }}
                                    </v-col>

                                    <v-col cols="1" />
                                    <v-col cols="5" class="py-0 text-caption">
                                        <b>Location</b>
                                    </v-col>
                                    <v-col cols="1" />
                                    <v-col cols="5" class="py-0 text-caption">
                                        {{ getTeam.stadiumName }}, {{ getTeam.stadiumLocation }}
                                    </v-col>

                                    <v-col cols="1" />
                                    <v-col cols="5" class="py-0 text-caption">
                                        <b>Sponsor</b>
                                    </v-col>
                                    <v-col cols="1" />
                                    <v-col cols="5" class="py-0 text-caption">
                                        {{ getTeam.stadiumSponsor }}
                                    </v-col>

                                    <v-col cols="5" class="py-1">
                                        <b>Bainisteoir </b>
                                    </v-col>
                                    <v-col cols="2" />
                                    <v-col cols="5" class="py-1">
                                        {{ getTeam.manager }}
                                    </v-col>

                                    <v-col cols="5" class="py-1">
                                        <b>Ranking </b>
                                    </v-col>
                                    <v-col cols="2" />
                                    <v-col cols="5" class="py-1">
                                        {{ getTeamRanking ? getTeamRanking.position : '' }}
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </base-card>
            </v-col>
            <v-col md="8" cols="12" :class="$vuetify.breakpoint.xsOnly ? 'pt-0' : ''">
                <v-row>
                    <v-col sm="6" cols="12" :class="$vuetify.breakpoint.xsOnly ? 'pt-0' : 'py-0'">
                        <base-card class="h-full">
                            <v-row>
                                <v-col cols="12" class="text-center">
                                    <div class="card-title ma-0 text-h6">Honours</div>
                                </v-col>
                                <v-col cols="12" class="my-0 py-0">
                                    <v-card-text class="py-0">
                                        <v-row>
                                            <v-col cols="12" class="py-1">
                                                <v-divider />
                                                <v-data-table dense
                                                    :headers="[{ text: 'Competition', value: 'tournament', sortable: false }, { text: '', value: 'winner', sortable: false }, { text: 'Titles', value: 'count', sortable: false }]"
                                                    :items="getTeamWinners" :custom-sort="sortHonours"
                                                    hide-default-footer mobile-breakpoint="0">
                                                    <template v-slot:item.winner="{ item }">
                                                        {{ item.winner ? 'Winner' : 'Runner Up' }}
                                                    </template>
                                                </v-data-table>
                                                <v-divider />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row>
                                            <v-col style="justify-content: center; display: flex;">
                                                <v-btn :plain="!$vuetify.theme.dark" text @click="gotToHonours()">View
                                                    Details</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-col>
                            </v-row>
                        </base-card>
                    </v-col>
                    <v-col sm="6" cols="12" class="py-0">
                        <base-card class="h-full">
                            <v-row>
                                <v-col cols="12" class="text-center">
                                    <div class="card-title ma-0 text-h6">Standings</div>
                                </v-col>
                                <v-col cols="12" class="my-0 py-0">
                                    <v-card-text class="py-0">
                                        <v-row>
                                            <v-col cols="12" class="py-1">
                                                <v-divider />
                                                <v-data-table dense :headers="[
                                                    { text: 'Pos', value: 'position', sortable: false },
                                                    { text: 'Team', value: 'team', sortable: false },
                                                    { text: '+/-', value: 'diff', sortable: false },
                                                    { text: 'Points', value: 'points', sortable: false }
                                                ]" :items="getCurrentStandings" hide-default-footer
                                                    mobile-breakpoint="0">

                                                    <template v-slot:item.position="{ item }">
                                                        {{ item.position != 0 ? item.position : '-' }}
                                                    </template>

                                                    <template v-slot:item.team="{ item }">
                                                        {{ item.teamEnt.name }}
                                                    </template>

                                                    <template v-slot:item.diff="{ item }">
                                                        {{ item.pointsDifferenceFor - item.pointsDifferenceAgainst }}
                                                    </template>

                                                    <template v-slot:footer>
                                                        <div style="justify-content: center; display: flex;"
                                                            v-if="getTeam.division == 'Munster' || getTeam.division == 'Leinster'">
                                                            <v-btn :plain="!$vuetify.theme.dark" text
                                                                @click="goToStandings()">
                                                                View Details
                                                            </v-btn>
                                                        </div>
                                                    </template>
                                                </v-data-table>
                                                <v-divider />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </base-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <base-card class="h-full">
                            <v-row>
                                <v-col cols="12" class="my-0 py-0">
                                    <v-card-text class="py-0">
                                        <v-tabs v-model="tab" centered ref="tabsItems">

                                            <v-tab>
                                                Results
                                            </v-tab>

                                            <v-tab>
                                                Fixtures
                                            </v-tab>

                                        </v-tabs>

                                        <v-tabs-items v-model="tab" touchless>
                                            <v-tab-item :key="0">
                                                <div>
                                                    <v-row>
                                                        <v-col>
                                                            <v-data-table dense :headers="resultHeaders"
                                                                :items="results" hide-default-footer
                                                                mobile-breakpoint="0">
                                                                <template v-slot:item.opponentLogo="{ item }">
                                                                    <v-img
                                                                        :src="`/assets/gaa/teams/icons/${item.opponentLogo}.WEBP`"
                                                                        :alt="item.opponentLogo" contain max-width="30"
                                                                        class="img_border"></v-img>
                                                                </template>
                                                                <template v-slot:item.opponent="{ item }">
                                                                    <h6 class="ma-0 font-weight-medium">
                                                                        {{ item.opponent }}
                                                                    </h6>
                                                                </template>

                                                                <template v-slot:item.result="{ item }">
                                                                    <span :style="calculateResultColour(item)">{{
                                                                        calculateResultText(item) }}</span>
                                                                </template>
                                                                <template v-slot:footer>
                                                                    <div
                                                                        style="justify-content: center; display: flex;">
                                                                        <v-btn :plain="!$vuetify.theme.dark" text
                                                                            @click="goToResultHistory()">
                                                                            View All Time
                                                                        </v-btn>
                                                                        <v-btn
                                                                            v-if="getTeam.division == 'Munster' || getTeam.division == 'Leinster'"
                                                                            :plain="!$vuetify.theme.dark" text
                                                                            @click="goToResults()" class="ml-2">
                                                                            View SHC 25
                                                                        </v-btn>
                                                                    </div>
                                                                </template>
                                                            </v-data-table>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-tab-item>
                                            <v-tab-item :key="2">
                                                <div>
                                                    <v-row>
                                                        <v-col>
                                                            <v-data-table dense :headers="fixtureHeaders"
                                                                :items="fixtures" hide-default-footer
                                                                class="elevation-1" mobile-breakpoint="0">

                                                                <template v-slot:item.opponentLogo="{ item }">
                                                                    <v-img
                                                                        :src="`/assets/gaa/teams/icons/${item.opponentLogo}.WEBP`"
                                                                        :alt="item.opponentLogo" contain max-width="30"
                                                                        class="img_border"></v-img>
                                                                </template>
                                                                <template v-slot:item.opponent="{ item }">
                                                                    <h6 class="ma-0 font-weight-medium">
                                                                        {{ item.opponent }}
                                                                    </h6>
                                                                </template>

                                                                <template v-slot:item.date="{ item }">
                                                                    <div>{{ item.time }} {{ item.date }}</div>
                                                                </template>

                                                                <template v-slot:item.coverage="{ item }">
                                                                    <div>
                                                                        <v-img contain
                                                                            :max-width="$vuetify.breakpoint.xsOnly ? 50 : 80"
                                                                            :src="`/assets/tv/logo/${item.coverage}.jpg`"
                                                                            :alt="item.coverage" />
                                                                    </div>
                                                                </template>
                                                                <template v-slot:footer>
                                                                    <div
                                                                        style="justify-content: center; display: flex;">
                                                                        <v-btn :plain="!$vuetify.theme.dark" text
                                                                            @click="goToFixtures()">
                                                                            View Details
                                                                        </v-btn>
                                                                    </div>
                                                                </template>
                                                            </v-data-table>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </base-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: ['teamId'],
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Team Profile',
    },
    data() {
        return {
            tab: 0,
            loading: true,
            headers: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'opponentLogo',
                },
                { text: 'Opponent', value: 'opponent', sortable: false, },
                { text: 'Result', value: 'result', sortable: false, },
                { text: 'Date', value: 'date', sortable: false, },
                { text: 'Venue', value: 'venue', sortable: false, },
                { text: 'Competition', value: 'competition', sortable: false, },
                { text: 'Round', value: 'round', sortable: false, },
                { text: 'TV', value: 'coverage', sortable: false, }
            ]
        }
    },
    async mounted() {
        await this.fetchTeam(this.$route.params.teamId);
        this.loading = false;
        this.fetchFixturesForTeam({ "competitionId": this.getCompetitionId, "teamId": this.$route.params.teamId });
        this.fetchCompetitionStandingsForWeek({ "competitionId": this.$store.getters.getCompetitionId, "name": this.getTeam.division.toUpperCase(), "gameweek": this.getCurrentGameweek - 1 });
        this.fetchWinnersForTeam(this.$route.params.teamId);
        this.fetchRankingForTeam(this.$route.params.teamId);
    },
    methods: {
        ...mapActions(['fetchTeam', 'fetchFixturesForTeam', 'fetchCompetitionStandingsForWeek', 'fetchWinnersForTeam', 'fetchRankingForTeam']),
        handleButtonClick() { },
        goToResults() {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/matches/results?team=${this.getTeam.name}`)
        },
        goToResultHistory() {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/headtohead?team=${this.getTeam.id}&opposition=All`)
        },
        goToFixtures() {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/matches/fixtures?team=${this.getTeam.name}`)
        },
        goToStandings() {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/standings/${this.getTeam.division.toLowerCase()}`)
        },
        gotToHonours() {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/honours/5/${this.$route.params.teamId}`)
        },
        sortHonours(items) {
            items.sort((a, b) => {
                if (a.winner == b.winner) {
                    if (a.tournament === b.tournament) {
                        return 0;
                    } else if (a.tournament === 'All Ireland') {
                        return -1;
                    } else {
                        return 1;
                    }
                } else if (a.winner && !b.winner) {
                    return -1;
                } else {
                    return 1;
                }

                return a.tournament.localeCompare(b.tournament);  // Default alphabetic comparison
            });
            return items;
        },
        calculateResultColour(item) {
            if (item.result == 'HOME_WIN') {
                return 'color: red';
            } else if (item.result == 'AWAY_WIN') {
                return 'color: green';
            } else {
                return '';
            }
        },
        calculateResultText(item) {
            if (item.homeTeam && item.homeTeam.id == this.$route.params.teamId) {
                return `${item.homeGoals}-${item.homePoints} ${item.awayGoals}-${item.awayPoints}`
            } else {
                return `${item.awayGoals}-${item.awayPoints} ${item.homeGoals}-${item.homePoints}`
            }
        },
        openMapWithAddress(address) {
            const encodedAddress = encodeURIComponent(address);

            // Determine if the user is on a mobile device
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

            // Google Maps URL for web and Android devices
            let mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

            // Apple Maps for iOS devices
            if (isMobile && /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                mapUrl = `http://maps.apple.com/?q=${encodedAddress}`;
            }

            // Open the map URL in a new tab (or trigger the map app on mobile)
            window.open(mapUrl, '_blank');
        }
    },
    computed: {
        ...mapGetters(["getTeam", "getCompetitionId", "getTeamFixtures", 'getCurrentGameweek', 'getCurrentStandings', 'getTeamWinners', 'getTeamRanking']),
        results() {
            let results = [];
            results.concat(this.getTeamFixtures.filter(it => it.matchResult));
            return results;
        },
        fixtures() {
            let fixtures = this.getTeamFixtures.filter(it => !it.matchResult);
            return fixtures.map(fixture => ({
                opponentLogo: fixture.homeTeam.id != this.$route.params.teamId ? fixture.homeTeam.code : fixture.awayTeam.code,
                opponent: fixture.homeTeam.id != this.$route.params.teamId ? fixture.homeTeam.name : fixture.awayTeam.name,
                date: new Date(fixture.date).toLocaleDateString(),
                venue: fixture.venue,
                competition: fixture.stage,
                round: fixture.matchdayLabel,
                coverage: fixture.coverage
            }));
        },
        resultHeaders() {
            if (this.$vuetify.breakpoint.xsOnly) {
                let activeHeaders = this.headers.filter(
                    it => (it.value != "coverage" & it.value != 'date' & it.value != 'venue' & it.value != 'competition')
                );
                return activeHeaders;
            }
            return this.headers.filter(it => (it.value != "coverage"))
        },
        fixtureHeaders() {
            if (this.$vuetify.breakpoint.xsOnly) {
                let activeHeaders = this.headers.filter(
                    it => (it.value != "result" & it.value != 'venue' & it.value != 'competition' & it.value != 'round')
                );
                return activeHeaders;
            }
            return this.headers.filter(it => (it.value != "result"))
        }
    }
}
</script>

<style scoped>
.img_border {
    border-style: solid;
    border-width: 2px;
}
</style>